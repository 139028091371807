.home-wrap {
  position: relative; }
  .home-wrap .home-slider-wrap {
    position: relative;
    height: calc(100vh - 160px); }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider-wrap {
        height: calc(100vh - 200px); } }
    .home-wrap .home-slider-wrap .home-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      height: calc(100vh - 160px); }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider-wrap .home-slider {
          height: calc(100vh - 200px); } }
      .home-wrap .home-slider-wrap .home-slider .carousel-control-prev-icon {
        display: none; }
      .home-wrap .home-slider-wrap .home-slider .carousel-control-next-icon {
        display: none; }
      .home-wrap .home-slider-wrap .home-slider .carousel-control-prev {
        position: absolute;
        opacity: 1;
        left: 0;
        top: 50%;
        height: 60px;
        width: 40px;
        margin-top: -30px; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider-wrap .home-slider .carousel-control-prev {
            top: unset;
            bottom: 20px;
            left: 40px; } }
        .home-wrap .home-slider-wrap .home-slider .carousel-control-prev::after {
          content: ' ';
          border-left: 4px solid #FFFFFF;
          border-top: 4px solid #FFFFFF;
          transform: rotate(-45deg);
          width: 60px;
          height: 60px;
          position: absolute;
          left: 40px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider-wrap .home-slider .carousel-control-prev::after {
              width: 24px;
              height: 24px; } }
      .home-wrap .home-slider-wrap .home-slider .carousel-control-next {
        position: absolute;
        opacity: 1;
        right: 0;
        top: 50%;
        height: 60px;
        width: 40px;
        margin-top: -30px; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider-wrap .home-slider .carousel-control-next {
            top: unset;
            bottom: 20px;
            left: 50%; } }
        .home-wrap .home-slider-wrap .home-slider .carousel-control-next::after {
          content: ' ';
          border-left: 4px solid #FFFFFF;
          border-top: 4px solid #FFFFFF;
          transform: rotate(135deg);
          width: 60px;
          height: 60px;
          position: absolute;
          right: 40px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider-wrap .home-slider .carousel-control-next::after {
              width: 24px;
              height: 24px; } }
      .home-wrap .home-slider-wrap .home-slider .carousel-inner {
        height: 100%; }
      .home-wrap .home-slider-wrap .home-slider .carousel-item {
        height: 100%; }
        .home-wrap .home-slider-wrap .home-slider .carousel-item img {
          height: 100%;
          width: 100%;
          object-fit: cover; }
        .home-wrap .home-slider-wrap .home-slider .carousel-item .content {
          position: absolute;
          padding-top: 250px;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%; }
          @media only screen and (max-width: 767px) {
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content {
              padding-top: 100px; } }
          .home-wrap .home-slider-wrap .home-slider .carousel-item .content .container {
            height: 100%; }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .container .row {
              height: 100%; }
          .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col {
            position: relative;
            height: 100%; }
            @media only screen and (max-width: 767px) {
              .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col {
                padding-left: 30px; } }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col .caption {
              position: absolute;
              top: 55px; }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col .overlay {
              position: absolute;
              background-color: #C42026;
              width: 100%;
              width: calc(100% - 85px);
              height: 100%;
              opacity: 0.7;
              margin-left: 85px; }
              @media only screen and (max-width: 767px) {
                .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col .overlay {
                  margin-left: 30px;
                  width: calc(100% - 30px); } }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col h3 {
              font-size: 84px;
              line-height: 80px;
              font-weight: 700;
              color: #FFFFFF;
              position: relative;
              margin-bottom: 18px; }
              @media only screen and (max-width: 767px) {
                .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col h3 {
                  font-size: 26px;
                  line-height: 24px;
                  margin-bottom: 8px; } }
              .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col h3::after {
                content: ' ';
                display: block;
                height: 2px;
                width: 80px;
                background-color: #FCB818;
                position: absolute;
                top: -20px;
                left: 40px; }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col h6 {
              position: absolute;
              margin-left: -62px;
              margin-top: 40px;
              color: #FFFFFF;
              font-size: 22px;
              font-weight: 500;
              transform: rotate(-90deg);
              width: auto; }
              @media only screen and (max-width: 767px) {
                .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col h6 {
                  font-size: 14px;
                  margin-left: -40px;
                  margin-top: 24px; } }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col p {
              color: #FFFFFF;
              font-size: 16px;
              margin-bottom: 46px; }
              @media only screen and (max-width: 767px) {
                .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col p {
                  font-size: 12px;
                  margin-bottom: 16px; } }
            .home-wrap .home-slider-wrap .home-slider .carousel-item .content .content-col .button {
              background-color: #FCB818;
              padding: 11px 32px;
              font-size: 12px;
              font-weight: 600;
              color: #FFFFFF;
              border-radius: 36px; }
    .home-wrap .home-slider-wrap .indicator {
      position: absolute;
      left: 50%;
      bottom: 40px;
      margin-left: 419px;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .home-slider-wrap .indicator {
          left: unset;
          right: 15px;
          margin-left: unset; } }
      .home-wrap .home-slider-wrap .indicator span {
        font-size: 14px;
        font-weight: 600;
        color: #FCB818; }
        .home-wrap .home-slider-wrap .indicator span:last-child {
          color: #FFFFFF; }
      .home-wrap .home-slider-wrap .indicator div {
        height: 4px;
        background-color: #FFFFFF;
        width: 100px;
        display: block;
        margin: 0 10px; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider-wrap .indicator div {
            width: 50px; } }

.certificates {
  padding-top: 40px; }
  .certificates h6 {
    font-size: 26px;
    font-weight: 600;
    color: #222222;
    margin-bottom: 7px; }
    @media only screen and (max-width: 1200px) {
      .certificates h6 {
        font-size: 18px; } }
  .certificates p {
    color: #959595;
    font-size: 16px;
    font-weight: 300; }
    @media only screen and (max-width: 1200px) {
      .certificates p {
        font-size: 12px; } }
  .certificates .items {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .certificates .items img {
      max-height: 60px; }
    @media only screen and (max-width: 767px) {
      .certificates .items img {
        max-height: 50px; } }

.section-banner {
  height: 430px;
  position: relative; }
  .section-banner img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 767px) {
    .section-banner h6 {
      font-size: 18px; } }
  .section-banner h4 {
    font-size: 34px;
    color: #FFFFFF;
    font-weight: 700;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .section-banner h4 {
        font-size: 20px; } }

.title-with-icon {
  position: relative;
  padding-top: 60px;
  text-align: center;
  font-size: 22px;
  font-weight: 600; }
  .title-with-icon::after {
    content: ' ';
    display: block;
    background-image: url(./assets/images/title.svg);
    width: 46px;
    height: 47px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -23px; }

.page-wrap {
  padding-top: 130px; }
  @media only screen and (max-width: 768px) {
    .page-wrap {
      padding-top: 80px; } }
  .page-wrap .page-into {
    height: 280px;
    position: relative;
    margin-bottom: 70px; }
    @media only screen and (max-width: 768px) {
      .page-wrap .page-into {
        height: 180px;
        margin-bottom: 20px; } }
    .page-wrap .page-into img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .page-wrap .page-into .container {
      height: 100%; }
      .page-wrap .page-into .container .row {
        height: 100%;
        align-items: center; }
        @media only screen and (max-width: 768px) {
          .page-wrap .page-into .container .row {
            padding-top: 45px; } }
    .page-wrap .page-into .breadcrumb {
      display: flex;
      justify-content: flex-end;
      background-color: unset;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .page-wrap .page-into .breadcrumb {
          display: none; } }
      .page-wrap .page-into .breadcrumb li {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600; }
        .page-wrap .page-into .breadcrumb li a {
          color: #FFFFFF; }
        .page-wrap .page-into .breadcrumb li::after {
          content: ' / ';
          margin: 0 8px; }
        .page-wrap .page-into .breadcrumb li:last-child {
          color: #FCB818; }
          .page-wrap .page-into .breadcrumb li:last-child a {
            color: #FCB818; }
          .page-wrap .page-into .breadcrumb li:last-child::after {
            display: none; }
    .page-wrap .page-into .overlay {
      background-color: #C42026;
      opacity: 0.7;
      width: 380px;
      height: 213px;
      position: absolute;
      left: 50%;
      margin-left: -490px;
      bottom: 0; }
      @media only screen and (max-width: 768px) {
        .page-wrap .page-into .overlay {
          left: 50px;
          margin-left: 0;
          width: 70%;
          height: 150px; } }
    .page-wrap .page-into h1 {
      color: #FFFFFF;
      font-weight: 600;
      font-size: 46px;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .page-wrap .page-into h1 {
          font-size: 36px; } }
      .page-wrap .page-into h1::after {
        content: ' ';
        display: block;
        width: 60px;
        height: 2px;
        background-color: #FCB818;
        position: absolute;
        left: 40px;
        top: -20px; }
        @media only screen and (max-width: 768px) {
          .page-wrap .page-into h1::after {
            top: -10px; } }

@media only screen and (max-width: 768px) {
  .section .text-table p {
    font-size: 12px !important; } }

.section img {
  width: 100%;
  margin-bottom: 15px; }

.section .title {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 64px; }
  @media only screen and (max-width: 768px) {
    .section .title {
      font-size: 32px; } }
  .section .title::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 80px;
    height: 2px;
    background-color: #FCB818;
    left: 50%;
    margin-left: -40px; }

.section .left-title {
  text-align: left;
  font-size: 42px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 64px; }
  @media only screen and (max-width: 768px) {
    .section .left-title {
      font-size: 32px;
      margin-bottom: 30px; } }
  .section .left-title::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 80px;
    height: 2px;
    background-color: #FCB818;
    left: 0; }

.section strong {
  font-size: 16px;
  font-weight: 600; }

.section p {
  font-size: 16px;
  font-weight: 300; }

.section ul {
  list-style: none;
  padding: 0; }
  .section ul li {
    padding-left: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    font-size: 16px;
    font-weight: 300; }
    .section ul li::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 5px;
      left: 0;
      background-image: url(./assets/images/item.svg);
      width: 24px;
      height: 20px; }

.our-mission {
  background-color: #F5F5F5;
  padding: 50px 0; }

.section-banner .title-with-icon {
  color: #FFFFFF;
  margin-top: 60px;
  margin-bottom: 27px; }

.contact-section {
  margin-top: 50px;
  position: relative;
  padding: 60px 0; }
  .contact-section::after {
    content: ' ';
    display: block;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #222222;
    position: absolute; }
    @media only screen and (max-width: 768px) {
      .contact-section::after {
        width: 100%;
        height: 50%;
        left: 0; } }
  .contact-section .col-lg-6 {
    z-index: 2; }
  .contact-section::before {
    content: ' ';
    display: block;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #FCB818;
    position: absolute; }
    @media only screen and (max-width: 768px) {
      .contact-section::before {
        width: 100%;
        height: 50%;
        top: 50%; } }
  .contact-section .right-col {
    z-index: 9;
    padding-left: 70px; }
    @media only screen and (max-width: 768px) {
      .contact-section .right-col {
        padding-left: 15px;
        padding-top: 30px; } }
  .contact-section h3 {
    color: #FFFFFF;
    font-size: 42px;
    font-weight: 700;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 16px; }
    .contact-section h3::after {
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      width: 80px;
      height: 2px;
      background-color: #FFFFFF; }
  .contact-section h6 {
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 40px; }
  .contact-section p {
    color: #FFFFFF; }
  .contact-section ul {
    list-style: none;
    color: #FFFFFF;
    padding: 0;
    margin-bottom: 40px; }
    .contact-section ul li {
      margin: 17px 0;
      display: flex;
      align-items: center; }
      .contact-section ul li .isvg {
        margin-right: 16px; }
      .contact-section ul li:first-child .isvg {
        margin-left: -5px; }
      .contact-section ul li:last-child .isvg {
        margin-left: -2px; }
  .contact-section .social {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
    .contact-section .social li {
      width: 46px;
      height: 46px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px; }
      .contact-section .social li .isvg {
        margin: 0 !important; }
  .contact-section .monte-klas img {
    height: 60px; }

.form-field {
  border: 0;
  border-bottom: 2px solid #FFFFFF;
  background-color: transparent;
  width: 100%;
  margin-bottom: 20px;
  color: #FFFFFF;
  padding: 20px 0; }

.form-button {
  border: 2px solid #FFFFFF;
  width: 100%;
  color: #FFFFFF;
  background-color: transparent;
  padding: 15px;
  font-size: 18px;
  font-weight: 600; }

.map-wrap {
  width: 100%;
  height: 660px; }
  @media only screen and (max-width: 768px) {
    .map-wrap {
      height: 300px; } }
  .map-wrap .map {
    width: 100%;
    height: 100%; }

.news {
  padding-bottom: 100px;
  border-bottom: 1px solid #959595; }
  .news a {
    text-decoration: none; }
  .news article {
    width: 100%;
    border: 1px solid #D2D2D2;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px; }
    .news article img {
      width: 100%;
      height: 200px;
      object-fit: cover; }
    .news article h6 {
      text-align: center;
      color: #222222;
      font-size: 25px;
      font-weight: 600;
      margin-top: 17px;
      margin-bottom: 9px;
      height: 90px; }
    .news article p {
      text-align: center;
      color: #222222;
      font-size: 20px;
      margin-bottom: 10px; }
    .news article .date {
      color: #D2D2D2; }
    .news article button {
      background-color: #FCB818;
      border: 0;
      outline: none;
      color: #FFFFFF;
      padding: 6px 20px;
      font-size: 13px;
      margin-bottom: 12px; }

.side-news h3 {
  color: #222222;
  font-size: 26px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 20px; }
  .side-news h3::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    width: 80px;
    height: 2px;
    background-color: #FCB818; }

.side-news a {
  text-decoration: none; }

.side-news article {
  width: 100%;
  border: 1px solid #D2D2D2;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px; }
  .side-news article img {
    width: 100%;
    height: 135px;
    object-fit: cover; }
  .side-news article h6 {
    text-align: center;
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 9px; }
  .side-news article p {
    text-align: center;
    color: #222222;
    font-size: 14px;
    margin-bottom: 10px; }
  .side-news article .date {
    color: #D2D2D2; }
  .side-news article button {
    background-color: #FCB818;
    border: 0;
    outline: none;
    color: #FFFFFF;
    padding: 6px 20px;
    font-size: 13px;
    margin-bottom: 12px; }

.news-detail h2 {
  font-size: 36px;
  font-weight: 700; }

.news-detail .date {
  color: #D2D2D2; }

.news-detail p {
  font-size: 16px;
  margin-bottom: 15px; }

.news-detail img {
  width: 100%;
  object-fit: contain;
  margin-bottom: 36px; }

.contact-page-section {
  margin-top: 50px;
  position: relative;
  padding: 60px 0; }
  @media only screen and (max-width: 768px) {
    .contact-page-section {
      padding-top: 0; } }
  .contact-page-section .right-col {
    z-index: 9;
    padding-left: 70px; }
    @media only screen and (max-width: 768px) {
      .contact-page-section .right-col {
        padding-left: 15px;
        padding-top: 30px; } }
  .contact-page-section h3 {
    color: #222222;
    font-size: 42px;
    font-weight: 700;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 16px; }
    .contact-page-section h3::after {
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      width: 80px;
      height: 2px;
      background-color: #FCB818; }
  .contact-page-section h6 {
    font-size: 20px;
    color: #222222;
    margin-bottom: 60px; }
  .contact-page-section p {
    color: #222222; }
  .contact-page-section .monte-klas img {
    height: 60px; }
  .contact-page-section ul {
    list-style: none;
    color: #222222;
    padding: 0;
    margin-bottom: 40px; }
    .contact-page-section ul li {
      margin: 17px 0;
      display: flex;
      align-items: center; }
      .contact-page-section ul li .isvg {
        margin-right: 16px; }
        .contact-page-section ul li .isvg svg path, .contact-page-section ul li .isvg svg circle {
          fill: #222222 !important; }
      .contact-page-section ul li:first-child .isvg {
        margin-left: -5px; }
      .contact-page-section ul li:last-child .isvg {
        margin-left: -2px; }
  .contact-page-section .social {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
    .contact-page-section .social li {
      width: 46px;
      height: 46px;
      background-color: #F5F5F5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px; }
      .contact-page-section .social li .isvg {
        margin: 0 !important; }
  .contact-page-section .form-field {
    border-bottom: 2px solid #959595;
    color: #222222; }
  .contact-page-section .form-button {
    border: 2px solid #C42026;
    color: #222222; }

.competition-form .form-field {
  border-bottom: 2px solid #959595;
  color: #222222; }
  .competition-form .form-field .select-field {
    padding: 0; }
    .competition-form .form-field .select-field .dropdown-toggle {
      padding: 0;
      position: relative;
      color: #222222; }
      .competition-form .form-field .select-field .dropdown-toggle::after {
        all: unset;
        content: ' ';
        border-bottom: 2px solid #C42026;
        border-right: 2px solid #C42026;
        width: 12px;
        height: 12px;
        display: block;
        transform: rotate(45deg);
        position: absolute;
        right: 5px;
        top: 8px; }
    .competition-form .form-field .select-field .dropdown-menu {
      width: 100%;
      margin-top: 22px; }

.competition-form .form-button {
  border: 2px solid #C42026;
  color: #222222; }

.competition-form .form-file-field {
  position: relative;
  padding: 10px 0; }
  .competition-form .form-file-field input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%; }
  .competition-form .form-file-field > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .competition-form .form-file-field > div button {
      border: 1px solid #959595;
      background-color: transparent;
      padding: 9px 36px;
      color: #959595;
      font-size: 16px; }

.comeptition-section {
  padding-bottom: 100px;
  border-bottom: 1px solid #959595; }
  .comeptition-section .title {
    text-align: left;
    margin-bottom: 19px; }
    .comeptition-section .title::after {
      left: 0;
      margin-left: 0; }
  .comeptition-section h6 {
    margin-bottom: 40px; }

.documents-section {
  padding-bottom: 100px;
  border-bottom: 1px solid #959595; }
  .documents-section .title {
    text-align: left;
    margin-bottom: 19px; }
    .documents-section .title::after {
      left: 0;
      margin-left: 0; }
  .documents-section h6 {
    margin-bottom: 40px; }
  .documents-section p {
    font-size: 22px;
    font-weight: 600; }
  .documents-section ul li {
    padding: 14px 23px;
    border: 4px solid #F5F5F5;
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    .documents-section ul li a {
      color: #C42026;
      font-weight: 400; }
    .documents-section ul li::before {
      display: none; }
    .documents-section ul li img {
      margin-right: 10px;
      width: unset;
      margin-bottom: unset; }

.toolbox .tabs button {
  background-color: #fff;
  border: 1px solid #a1a1a1;
  padding: 5px 20px; }

.work-section {
  position: relative; }
  .work-section * {
    min-height: 150px;
    border: 2px dotted #a1a1a1;
    margin-bottom: 15px;
    position: relative;
    margin-top: 50px;
    padding: 0 15px; }
  .work-section h1, .work-section h2, .work-section h3, .work-section h4, .work-section h5, .work-section h6, .work-section p {
    min-height: 40px;
    margin-top: 34px; }
    .work-section h1 *, .work-section h2 *, .work-section h3 *, .work-section h4 *, .work-section h5 *, .work-section h6 *, .work-section p * {
      all: unset; }
  .work-section > div {
    margin-top: 0; }
  .work-section [__active=true] {
    border: 2px dotted blue; }
  .work-section img {
    width: 100%; }
  .work-section ul {
    padding-left: 32px; }
  .work-section li {
    border: unset;
    min-height: unset;
    margin-bottom: unset;
    margin-top: unset;
    padding: unset; }
  .work-section .container {
    padding: 0 30px; }
  .work-section .new-element {
    width: 100%;
    height: 50px;
    min-height: 50px !important;
    background-color: transparent;
    margin-top: 20px; }
  .work-section .element-name {
    border: 0;
    min-height: unset;
    position: absolute;
    top: 20px;
    left: 20px;
    margin-top: 0;
    padding: 0;
    font-size: 10px; }

.props-modal {
  position: fixed;
  width: 500px;
  background-color: #FFFFFF;
  padding: 32px;
  -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  left: 50%;
  margin-left: -250px;
  top: 30%; }
  .props-modal .buttons {
    display: flex;
    justify-content: flex-end; }
    .props-modal .buttons button {
      margin-left: 10px;
      border: 2px solid #C42026;
      background-color: transparent; }
  .props-modal h6 {
    margin-bottom: 32px;
    font-size: 28px; }
  .props-modal .delete {
    position: fixed;
    top: 30%;
    margin-top: 40px;
    right: 50%;
    margin-right: -218px;
    border: 2px solid #C42026;
    background-color: transparent; }
  .props-modal ul {
    padding: 0; }
    .props-modal ul li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      position: relative; }
      .props-modal ul li input {
        width: 100%;
        padding: 2px 5px; }
        .props-modal ul li input:first-child {
          width: 100px;
          margin-right: 20px; }
      .props-modal ul li .file-upload {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 32px;
        margin-left: 10px;
        margin-right: 0; }
        .props-modal ul li .file-upload input {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          margin-right: 0;
          padding: 0;
          cursor: pointer; }
        .props-modal ul li .file-upload button {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 100%;
          border: 2px solid #C42026;
          background-color: transparent;
          padding: 0; }
          .props-modal ul li .file-upload button .isvg svg {
            height: 26px; }

.__element__toolbox {
  all: unset;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99; }
  .__element__toolbox * {
    all: unset; }
  .__element__toolbox button {
    margin: 0 10px;
    cursor: pointer; }
    .__element__toolbox button img {
      height: 20px; }

.contact-locations {
  margin-top: 100px; }
  .contact-locations > div {
    margin-bottom: 40px; }
  .contact-locations h6 {
    margin-bottom: 0; }
  .contact-locations p {
    margin-bottom: 5px; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background-color: #FFF; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Poppins', sans-serif; }

button {
  cursor: pointer; }

header {
  z-index: 3;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    header {
      padding: 5px 0; } }
  header .nav-container {
    display: flex;
    flex-direction: column; }
    header .nav-container .top-nav {
      border-bottom: 1px solid #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 1200px) {
        header .nav-container .top-nav {
          border-bottom: 0 !important; } }
      @media only screen and (max-width: 1200px) {
        header .nav-container .top-nav .info {
          display: none; } }
      header .nav-container .top-nav .info ul {
        list-style: none;
        padding: 5px 0;
        display: flex;
        margin-bottom: 0; }
        header .nav-container .top-nav .info ul li {
          display: flex;
          align-items: center;
          padding-right: 16px;
          font-size: 14px;
          color: #FFFFFF; }
          header .nav-container .top-nav .info ul li .isvg {
            padding-right: 5px; }
          header .nav-container .top-nav .info ul li:last-child .isvg {
            margin-top: -15px; }
      header .nav-container .top-nav .langs .dropdown .dropdown-toggle {
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;
        box-shadow: none; }
        header .nav-container .top-nav .langs .dropdown .dropdown-toggle img {
          width: 24px;
          margin-right: 8px; }
        @media only screen and (max-width: 1200px) {
          header .nav-container .top-nav .langs .dropdown .dropdown-toggle span {
            display: none; } }
        header .nav-container .top-nav .langs .dropdown .dropdown-toggle::after {
          content: ' ';
          margin-left: 9px;
          display: inline-block !important;
          width: 8px !important;
          height: 8px !important;
          border-left: 2px solid #FFFFFF !important;
          border-top: 2px solid #FFFFFF !important;
          border-right: unset !important;
          border-bottom: unset !important;
          margin-top: -5px;
          transform: rotate(-135deg); }
      header .nav-container .top-nav .langs .dropdown .dropdown-menu img {
        width: 24px;
        margin-right: 8px; }
  header .hamburger {
    display: none; }
    @media only screen and (max-width: 1200px) {
      header .hamburger {
        display: block;
        position: absolute;
        top: 35px;
        right: 15px;
        background-color: transparent;
        outline: none;
        border: 0; }
        header .hamburger svg {
          fill: #FFFFFF; } }
  header .row {
    align-items: center; }
  header .navigation-mobile {
    position: fixed;
    top: 0;
    left: 20%;
    width: 80%;
    height: 100vh;
    overflow-y: auto;
    background-color: #C42026;
    display: none;
    padding: 40px 15px;
    -webkit-animation-name: mobile-menu;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: mobile-menu;
    animation-duration: 0.5s;
    z-index: 99999;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }

@keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }
    @media only screen and (max-width: 1200px) {
      header .navigation-mobile {
        display: block; } }
    header .navigation-mobile ul {
      list-style: none;
      margin: 0; }
      header .navigation-mobile ul ul {
        padding: 10px 20px; }
    header .navigation-mobile li {
      padding: 12px 0;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 600; }
      header .navigation-mobile li a {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600; }
        header .navigation-mobile li a::after {
          display: none; }
    header .navigation-mobile .active::before {
      content: ' ';
      display: inline-block;
      background-image: url(./assets/images/item.svg);
      width: 24px;
      height: 20px;
      position: absolute;
      margin-left: -32px; }
  header .navigation {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    padding-left: 0;
    padding-top: 5px; }
    @media only screen and (max-width: 1200px) {
      header .navigation {
        display: none; } }
    header .navigation li {
      padding: 6px 10px; }
      header .navigation li a {
        color: #FFFFFF; }
      header .navigation li:last-child {
        border: 2px solid #FCB818; }
      header .navigation li .dropdown > button {
        background-color: transparent !important;
        border: 0 !important;
        font-size: 12px;
        font-weight: 600;
        outline: none !important;
        box-shadow: unset !important; }
        header .navigation li .dropdown > button::after {
          content: ' ';
          margin-left: 9px;
          display: inline-block !important;
          width: 8px !important;
          height: 8px !important;
          border-left: 2px solid #FFFFFF !important;
          border-top: 2px solid #FFFFFF !important;
          border-right: unset !important;
          border-bottom: unset !important;
          transform: rotate(-135deg); }
      header .navigation li .dropdown .dropdown-menu {
        background-color: #C42026;
        padding: 10px 0;
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        border: unset; }
        header .navigation li .dropdown .dropdown-menu button {
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 600;
          padding: 0; }
          header .navigation li .dropdown .dropdown-menu button a {
            padding: 6px 20px;
            width: 100%;
            float: left; }
          header .navigation li .dropdown .dropdown-menu button:hover {
            color: #C42026; }
            header .navigation li .dropdown .dropdown-menu button:hover a {
              color: #C42026;
              text-decoration: none; }

.page-header {
  background-color: #FFFFFF;
  z-index: 999;
  -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 768px) {
    .page-header {
      padding: 5px 0; } }
  .page-header .nav-container {
    display: flex;
    flex-direction: column; }
    .page-header .nav-container .top-nav {
      border-bottom: 1px solid #959595; }
      .page-header .nav-container .top-nav .info ul li {
        color: #222222; }
        .page-header .nav-container .top-nav .info ul li svg path, .page-header .nav-container .top-nav .info ul li svg circle {
          fill: #C42026 !important; }
      .page-header .nav-container .top-nav .langs .dropdown .dropdown-toggle {
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;
        box-shadow: none;
        color: #222222; }
        .page-header .nav-container .top-nav .langs .dropdown .dropdown-toggle img {
          width: 24px;
          margin-right: 8px; }
        .page-header .nav-container .top-nav .langs .dropdown .dropdown-toggle::after {
          content: ' ';
          margin-left: 9px;
          display: inline-block !important;
          width: 8px !important;
          height: 8px !important;
          border-left: 2px solid #C42026 !important;
          border-top: 2px solid #C42026 !important;
          border-right: unset !important;
          border-bottom: unset !important;
          margin-top: -5px;
          transform: rotate(-135deg); }
      .page-header .nav-container .top-nav .langs .dropdown .dropdown-menu img {
        width: 24px;
        margin-right: 8px; }
  @media only screen and (max-width: 768px) {
    .page-header .hamburger {
      top: 20px; } }
  .page-header .hamburger svg {
    fill: #222222; }
  @media only screen and (max-width: 768px) {
    .page-header .logo svg {
      height: 70px; } }
  .page-header .navigation {
    color: #222222;
    font-size: 12px;
    font-weight: 600; }
    .page-header .navigation li {
      position: relative; }
      .page-header .navigation li a {
        color: #222222; }
      .page-header .navigation li .dropdown > button {
        color: #222222 !important; }
        .page-header .navigation li .dropdown > button::after {
          border-left: 2px solid #C42026 !important;
          border-top: 2px solid #C42026 !important; }
      .page-header .navigation li .dropdown .dropdown-menu button {
        color: #FFFFFF !important; }
        .page-header .navigation li .dropdown .dropdown-menu button a {
          color: #FFFFFF !important; }
        .page-header .navigation li .dropdown .dropdown-menu button:hover {
          color: #C42026 !important; }
          .page-header .navigation li .dropdown .dropdown-menu button:hover a {
            color: #C42026 !important;
            text-decoration: none; }
      .page-header .navigation li:hover > a {
        color: #FCB818;
        text-decoration: none; }
      .page-header .navigation li:hover .dropdown > button {
        color: #FCB818 !important; }
        .page-header .navigation li:hover .dropdown > button .dropdown-menu button {
          color: #FFFFFF !important; }
          .page-header .navigation li:hover .dropdown > button .dropdown-menu button a {
            color: #FFFFFF !important; }
      .page-header .navigation li:hover::after {
        content: ' ';
        display: block;
        background-image: url(./assets/images/item.svg);
        width: 24px;
        height: 20px;
        position: absolute;
        left: 50%;
        margin-left: -12px;
        top: 100%; }
    .page-header .navigation .active > a {
      color: #FCB818;
      text-decoration: none; }
    .page-header .navigation .active .dropdown > button {
      color: #FCB818 !important; }
      .page-header .navigation .active .dropdown > button .dropdown-menu button {
        color: #FFFFFF !important; }
        .page-header .navigation .active .dropdown > button .dropdown-menu button a {
          color: #FFFFFF !important; }
    .page-header .navigation .active::after {
      content: ' ';
      display: block;
      background-image: url(./assets/images/item.svg);
      width: 24px;
      height: 20px;
      position: absolute;
      left: 50%;
      margin-left: -12px;
      top: 100%; }

footer {
  padding: 20px 0;
  width: 100%; }
  footer .row {
    align-items: center; }
  footer .spacer {
    height: 1px;
    background-color: #959595; }
  footer .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    footer .copyright p {
      padding: 20px 0 5px 0;
      margin-bottom: 0; }
      footer .copyright p a {
        font-weight: 600;
        color: #222222; }
  footer .navigation {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    padding-left: 0; }
    @media only screen and (max-width: 1200px) {
      footer .navigation {
        display: none; } }
    footer .navigation li {
      position: relative;
      padding: 6px 12px; }
      footer .navigation li a {
        color: #222222; }
      footer .navigation li .dropdown > button {
        background-color: transparent !important;
        border: 0 !important;
        font-size: 12px;
        font-weight: 600;
        outline: none !important;
        box-shadow: unset !important;
        color: #222222; }
        footer .navigation li .dropdown > button::after {
          content: ' ';
          margin-left: 9px;
          display: inline-block !important;
          width: 8px !important;
          height: 8px !important;
          border-left: 2px solid #C42026 !important;
          border-top: 2px solid #C42026 !important;
          border-right: unset !important;
          border-bottom: unset !important;
          transform: rotate(-135deg); }
      footer .navigation li .dropdown .dropdown-menu {
        background-color: #C42026;
        padding: 10px 0;
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        border: unset; }
        footer .navigation li .dropdown .dropdown-menu button {
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 600;
          padding: 6px 20px; }
          footer .navigation li .dropdown .dropdown-menu button:hover {
            color: #C42026; }
      footer .navigation li:hover a {
        color: #FCB818;
        text-decoration: none; }
      footer .navigation li:hover .dropdown > button {
        color: #FCB818 !important; }
      footer .navigation li:hover::after {
        content: ' ';
        display: block;
        background-image: url(./assets/images/item.svg);
        width: 24px;
        height: 20px;
        position: absolute;
        left: 50%;
        margin-left: -12px;
        top: 100%; }

.marquee {
  min-width: 100%;
  padding-top: 7px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  border-bottom: 1px solid #959595; }

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite; }

.marquee2 span {
  animation-delay: 10s; }

@keyframes marquee {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }
